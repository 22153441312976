body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
}

.oembed-container { 
	position: relative;
	overflow: auto;
	max-width: 100%;
  height: calc(100vh - 100px) !important;
} 

.oembed-container iframe,
.oembed-container object,
.oembed-container embed { 
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
  overflow: auto;
  height: calc(100vh - 100px) !important;
}

a:link {
  color: #424242;
  background-color: transparent;
}

.articleContentDiv img {
  max-width: 100%;
  margin: auto;
  height: auto;
}

.articleContentDiv h1 {
  font-size: 2.5em;
  line-height: calc(1ex / 0.42);
  margin: calc(1ex / 0.42) 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.articleContentDiv h2 {
  font-size: 2em;
  line-height: calc(1ex / 0.42);
  margin: calc(1ex / 0.42) 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.articleContentDiv h3 {
  font-size: 1.75em;
  line-height: calc(1ex / 0.38);
  margin: calc(1ex / 0.38) 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.articleContentDiv h4 {
  font-size: 1.5em;
  line-height: calc(1ex / 0.37);
  margin: calc(1ex / 0.37) 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.articleContentDiv p {
  font-size: 1em;
  line-height: calc(1ex / 0.32);
  margin: calc(1ex / 0.32) 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

a:visited {
  color: #424242;
  background-color: transparent;
}

#emojicom-trigger {
  bottom: 210px !important;
}

.searchLightTheme .MuiInputBase-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB3aWR0aD0iMTIzLjA1cHgiIGhlaWdodD0iMTIzLjA1cHgiIHZpZXdCb3g9IjAgMCAxMjMuMDUgMTIzLjA1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMjMuMDUgMTIzLjA1OyIKCSB4bWw6c3BhY2U9InByZXNlcnZlIiBzdHJva2U9IiM5ZTllOWUiIGZpbGw9IiM5ZTllOWUiPgo8Zz4KCTxwYXRoIGQ9Ik0xMjEuMzI1LDEwLjkyNWwtOC41LTguMzk5Yy0yLjMtMi4zLTYuMS0yLjMtOC41LDBsLTQyLjQsNDIuMzk5TDE4LjcyNiwxLjcyNmMtMi4zMDEtMi4zMDEtNi4xMDEtMi4zMDEtOC41LDBsLTguNSw4LjUKCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQKCQljMi4zLDIuMyw2LjEsMi4zLDguNSwwbDguNS04LjVjMi4zLTIuMywyLjMtNi4xLDAtOC41bC00Mi41LTQyLjRsNDIuNC00Mi4zOTlDMTIzLjYyNSwxNy4xMjUsMTIzLjYyNSwxMy4zMjUsMTIxLjMyNSwxMC45MjV6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
  background-size: 10px 10px;
}

.articleContentDiv pre {
  font-size: 12px;
  border: 2px solid grey;
  border-left: 12px solid #616161;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  /* Fixed line height */
  line-height: 15px;
  
  /* Use linear-gradient for background image */
  background-image: linear-gradient(180deg, #eee 50%, #fff 50%);
  
  /* Size background so that the height is 2x line-height */
  background-size: 100% 30px;
  
  /* Offset the background along the y-axis by top padding */
  background-position: 0 15px;
  overflow-x: scroll;
}

.articleContentDiv code {
  color: #005ae6;
  background-color: #f1f1f1;
  padding: 2px;
}


.articleContentDiv table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.articleContentDiv td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.articleContentDiv blockquote{
  width:75%;
  margin:25px auto;
  font-style:italic;
  color: #555555;
  padding:1.2em 30px 1.2em 75px;
  border-left:8px solid #005ae6 ;
  line-height:1.6;
  position: relative;
  background:#EDEDED;
}

blockquote {
  background: #EDEDED;
  border-left: 10px solid #005ae6;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}

.articleContentDiv blockquote::before{
  content: "\201C";
  color:#005ae6;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

.articleContentDiv blockquote::after{
  content: '';
}

.articleContentDiv blockquote span{
  display:block;
  color:#333333;
  font-style: normal;
  font-weight: bold;
  margin-top:1em;
}

.scrollGradient {
  background: 
    linear-gradient(#ffffff 33%, rgba(255,255,255, 0)),
    linear-gradient(rgba(255,255,255, 0), #ffffff 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(34,34,34, 0.5), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 50% 100%, rgba(34,34,34, 0.5), rgba(0,0,0,0)) 0 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 33px, 100% 33px, 100% 11px, 100% 11px;
}

.scrollableStationTagList ul {
  display: flex;
  align-items: center;
  list-style: none;
  max-width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.scrollableStationTagList ::-webkit-scrollbar {
  background: transparent;
  display: none;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}
